$enable-responsive-font-sizes: true;
@import '~bootstrap/scss/bootstrap';
@import 'vue-select/src/scss/vue-select.scss';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/brands';
@import '@fortawesome/fontawesome-free/scss/v4-shims';

@import '@trevoreyre/autocomplete-vue/dist/style';

// override some bs

.autocomplete-input {
   line-height: 1;
   background-color: #fff;
   background-image: none;
   padding: 0.375rem 0.75rem;
   color: #495057;
   border-radius: 0.25rem;
   height: calc(1.5em + 0.75rem + 2px);
   border: 1px solid #ced4da;
}

.autocomplete-result {
   padding: 0.375rem 0.75rem;
   background-image: none;
}

